import React from 'react';
import './App.css';

function App() {
    return (<React.Fragment>
        <div className="mb-0" id="home">
            <div className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
                 style={{backgroundImage: 'url(/assets/media/svg/illustrations/landing.svg)'}}>
                <div className="landing-header" data-kt-sticky="true" data-kt-sticky-name="landing-header"
                     data-kt-sticky-offset="{default: '200px', lg: '300px'}">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center flex-equal">
                                <button className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                                        id="kt_landing_menu_toggle"><i className="ki-outline ki-abstract-14 fs-2hx"></i>
                                </button>
                                <a href="../../demo34/dist/landing.html"><img alt="Logo"
                                                                              src="/assets/media/logos/landing.svg"
                                                                              className="logo-default h-25px h-lg-30px"/><img
                                    alt="Logo" src="/assets/media/logos/landing-dark.svg"
                                    className="logo-sticky h-20px h-lg-25px"/></a></div>
                            <div className="d-lg-block" id="kt_header_nav_wrapper">
                                <div className="d-lg-block p-5 p-lg-0" data-kt-drawer="true"
                                     data-kt-drawer-name="landing-menu"
                                     data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
                                     data-kt-drawer-width="200px" data-kt-drawer-direction="start"
                                     data-kt-drawer-toggle="#kt_landing_menu_toggle" data-kt-swapper="true"
                                     data-kt-swapper-mode="prepend"
                                     data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}">
                                    <div
                                        className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-600 menu-state-title-primary nav nav-flush fs-5 fw-semibold"
                                        id="kt_landing_menu">
                                        <div className="menu-item"><a
                                            className="menu-link nav-link active py-3 px-4 px-xxl-6" href="#kt_body"
                                            data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Home</a></div>
                                        <div className="menu-item"><a className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                                      href="#how-it-works" data-kt-scroll-toggle="true"
                                                                      data-kt-drawer-dismiss="true">How it Works</a>
                                        </div>
                                        <div className="menu-item"><a className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                                      href="#achievements" data-kt-scroll-toggle="true"
                                                                      data-kt-drawer-dismiss="true">Achievements</a>
                                        </div>
                                        <div className="menu-item"><a className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                                      href="#team" data-kt-scroll-toggle="true"
                                                                      data-kt-drawer-dismiss="true">Team</a></div>
                                        <div className="menu-item"><a className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                                      href="#portfolio" data-kt-scroll-toggle="true"
                                                                      data-kt-drawer-dismiss="true">Portfolio</a></div>
                                        <div className="menu-item"><a className="menu-link nav-link py-3 px-4 px-xxl-6"
                                                                      href="#pricing" data-kt-scroll-toggle="true"
                                                                      data-kt-drawer-dismiss="true">Pricing</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-equal text-end ms-1"><a
                                href="../../demo34/dist/authentication/layouts/corporate/sign-in.html"
                                className="btn btn-success">Sign In</a></div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9">
                    <div className="text-center mb-5 mb-lg-10 py-10 py-lg-20"><h1
                        className="text-white lh-base fw-bold fs-2x fs-lg-3x mb-15">Build An Outstanding Solutions<br/>with<span
                        id={'landing-hero-text'}><span id="kt_landing_hero_text">The Best Theme Ever</span></span></h1>
                        <a href="../../demo34/dist/index.html" className="btn btn-primary">Try Metronic</a></div>
                    <div className="d-flex flex-center flex-wrap position-relative px-5">
                        <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Fujifilm"><img
                            src="/assets/media/svg/brand-logos/fujifilm.svg" className="mh-30px mh-lg-40px" alt=""/>
                        </div>
                        <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Vodafone"><img
                            src="/assets/media/svg/brand-logos/vodafone.svg" className="mh-30px mh-lg-40px" alt=""/>
                        </div>
                        <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip"
                             title="KPMG International"><img src="/assets/media/svg/brand-logos/kpmg.svg"
                                                             className="mh-30px mh-lg-40px" alt=""/></div>
                        <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Nasa"><img
                            src="/assets/media/svg/brand-logos/nasa.svg" className="mh-30px mh-lg-40px" alt=""/></div>
                        <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Aspnetzero"><img
                            src="/assets/media/svg/brand-logos/aspnetzero.svg" className="mh-30px mh-lg-40px" alt=""/>
                        </div>
                        <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip"
                             title="AON - Empower Results"><img src="/assets/media/svg/brand-logos/aon.svg"
                                                                className="mh-30px mh-lg-40px" alt=""/></div>
                        <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Hewlett-Packard">
                            <img src="/assets/media/svg/brand-logos/hp-3.svg" className="mh-30px mh-lg-40px" alt=""/>
                        </div>
                        <div className="d-flex flex-center m-3 m-md-6" data-bs-toggle="tooltip" title="Truman"><img
                            src="/assets/media/svg/brand-logos/truman.svg" className="mh-30px mh-lg-40px" alt=""/></div>
                    </div>
                </div>
            </div>
            <div className="landing-curve landing-dark-color mb-10 mb-lg-20">
                <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                        fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <div className="mb-n10 mb-lg-n20 z-index-2">
            <div className="container">
                <div className="text-center mb-17">
                    <h3 className="fs-2hx text-dark mb-5" id="how-it-works"
                        data-kt-scroll-offset="{default: 100, lg: 150}">How it Works</h3>
                    <div className="fs-5 text-muted fw-bold">Save thousands to millions of bucks by using single
                        tool<br/>for different amazing and great useful admin
                    </div>
                </div>
                <div className="row w-100 gy-10 mb-md-20">
                    <div className="col-md-4 px-5">
                        <div className="text-center mb-10 mb-md-0"><img
                            src="/assets/media/illustrations/sketchy-1/2.png"
                            className="mh-125px mb-9" alt=""/>
                            <div className="d-flex flex-center mb-5"><span
                                className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">1</span>
                                <div className="fs-5 fs-lg-3 fw-bold text-dark">Jane Miller</div>
                            </div>
                            <div className="fw-semibold fs-6 fs-lg-4 text-muted">Save thousands to millions of
                                bucks<br/>by using single tool for different<br/>amazing and great
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 px-5">
                        <div className="text-center mb-10 mb-md-0"><img
                            src="/assets/media/illustrations/sketchy-1/8.png"
                            className="mh-125px mb-9" alt=""/>
                            <div className="d-flex flex-center mb-5"><span
                                className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">2</span>
                                <div className="fs-5 fs-lg-3 fw-bold text-dark">Setup Your App</div>
                            </div>
                            <div className="fw-semibold fs-6 fs-lg-4 text-muted">Save thousands to millions of
                                bucks<br/>by using single tool for different<br/>amazing and great
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 px-5">
                        <div className="text-center mb-10 mb-md-0"><img
                            src="/assets/media/illustrations/sketchy-1/12.png" className="mh-125px mb-9" alt=""/>
                            <div className="d-flex flex-center mb-5"><span
                                className="badge badge-circle badge-light-success fw-bold p-5 me-3 fs-3">3</span>
                                <div className="fs-5 fs-lg-3 fw-bold text-dark">Enjoy Nautica App</div>
                            </div>
                            <div className="fw-semibold fs-6 fs-lg-4 text-muted">Save thousands to millions of
                                bucks<br/>by using single tool for different<br/>amazing and great
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tns tns-default">
                    <div data-tns="true" data-tns-loop="true" data-tns-swipe-angle="false" data-tns-speed="2000"
                         data-tns-autoplay="true" data-tns-autoplay-timeout="18000" data-tns-controls="true"
                         data-tns-nav="false" data-tns-items="1" data-tns-center="false" data-tns-dots="false"
                         data-tns-prev-button="#kt_team_slider_prev1" data-tns-next-button="#kt_team_slider_next1">
                        <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10"><img
                            src="/assets/media/preview/demos/demo1/light-ltr.png"
                            className="card-rounded shadow mh-lg-650px mw-100" alt=""/></div>
                        <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10"><img
                            src="/assets/media/preview/demos/demo2/light-ltr.png"
                            className="card-rounded shadow mh-lg-650px mw-100" alt=""/></div>
                        <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10"><img
                            src="/assets/media/preview/demos/demo4/light-ltr.png"
                            className="card-rounded shadow mh-lg-650px mw-100" alt=""/></div>
                        <div className="text-center px-5 pt-5 pt-lg-10 px-lg-10"><img
                            src="/assets/media/preview/demos/demo5/light-ltr.png"
                            className="card-rounded shadow mh-lg-650px mw-100" alt=""/></div>
                    </div>
                    <button className="btn btn-icon btn-active-color-primary" id="kt_team_slider_prev1"><i
                        className="ki-outline ki-left fs-2x"></i></button>
                    <button className="btn btn-icon btn-active-color-primary" id="kt_team_slider_next1"><i
                        className="ki-outline ki-right fs-2x"></i></button>
                </div>
            </div>
        </div>
        <div className="mt-sm-n10">
            <div className="landing-curve landing-dark-color">
                <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                        fill="currentColor"></path>
                </svg>
            </div>
            <div className="pb-15 pt-18 landing-dark-bg">
                <div className="container">
                    <div className="text-center mt-15 mb-18" id="achievements"
                         data-kt-scroll-offset="{default: 100, lg: 150}"><h3
                        className="fs-2hx text-white fw-bold mb-5">We Make Things Better</h3>
                        <div className="fs-5 text-gray-700 fw-bold">Save thousands to millions of bucks by using single
                            tool<br/>for different amazing and great useful admin
                        </div>
                    </div>
                    <div className="d-flex flex-center">
                        <div
                            className="octagon-bg d-flex flex-wrap flex-center justify-content-lg-between mb-15 mx-auto w-xl-900px">
                            <div
                                className="d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain">
                                <i className="ki-outline ki-element-11 fs-2tx text-white mb-3"></i>
                                <div className="mb-0">
                                    <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                                        <div className="min-w-70px" data-kt-countup="true" data-kt-countup-value="700"
                                             data-kt-countup-suffix="+">0
                                        </div>
                                    </div>
                                    <span className="text-gray-600 fw-semibold fs-5 lh-0">Known Companies</span></div>
                            </div>
                            <div
                                className="octagon-bg d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain">
                                <i className="ki-outline ki-chart-pie-4 fs-2tx text-white mb-3"></i>
                                <div className="mb-0">
                                    <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                                        <div className="min-w-70px" data-kt-countup="true" data-kt-countup-value="80"
                                             data-kt-countup-suffix="K+">0
                                        </div>
                                    </div>
                                    <span className="text-gray-600 fw-semibold fs-5 lh-0">Statistic Reports</span></div>
                            </div>
                            <div
                                className="octagon-bg d-flex flex-column flex-center h-200px w-200px h-lg-250px w-lg-250px m-3 bgi-no-repeat bgi-position-center bgi-size-contain">
                                <i className="ki-outline ki-basket fs-2tx text-white mb-3"></i>
                                <div className="mb-0">
                                    <div className="fs-lg-2hx fs-2x fw-bold text-white d-flex flex-center">
                                        <div className="min-w-70px" data-kt-countup="true" data-kt-countup-value="35"
                                             data-kt-countup-suffix="M+">0
                                        </div>
                                    </div>
                                    <span className="text-gray-600 fw-semibold fs-5 lh-0">Secure Payments</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="fs-2 fw-semibold text-muted text-center mb-3"><span
                        className="fs-1 lh-1 text-gray-700">“</span>When you care about your topic, you’ll write about
                        it in a<br/><span className="text-gray-700 me-1">more powerful</span>, emotionally expressive
                        way<span className="fs-1 lh-1 text-gray-700">“</span></div>
                    <div className="fs-2 fw-semibold text-muted text-center"><a
                        href="../../demo34/dist/account/security.html" className="link-primary fs-4 fw-bold">Marcus
                        Levy,</a><span className="fs-4 fw-bold text-gray-600">KeenThemes CEO</span></div>
                </div>
            </div>
            <div className="landing-curve landing-dark-color">
                <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                        fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <div className="py-10 py-lg-20">
            <div className="container">
                <div className="text-center mb-12"><h3 className="fs-2hx text-dark mb-5" id="team"
                                                       data-kt-scroll-offset="{default: 100, lg: 150}">Our Great
                    Team</h3>
                    <div className="fs-5 text-muted fw-bold">It’s no doubt that when a development takes longer to
                        complete, additional costs to<br/>integrate and test each extra feature creeps up and haunts
                        most of us.
                    </div>
                </div>
                <div className="tns tns-default" style={{direction: 'ltr'}}>
                    <div data-tns="true" data-tns-loop="true" data-tns-swipe-angle="false" data-tns-speed="2000"
                         data-tns-autoplay="true" data-tns-autoplay-timeout="18000" data-tns-controls="true"
                         data-tns-nav="false" data-tns-items="1" data-tns-center="false" data-tns-dots="false"
                         data-tns-prev-button="#kt_team_slider_prev" data-tns-next-button="#kt_team_slider_next"
                         data-tns-responsive="{1200: {items: 3}, 992: {items: 2}}">
                        <div className="text-center">
                            <div
                                className="avatar-bg octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"></div>
                            <div className="mb-0"><a href="#" className="text-dark fw-bold text-hover-primary fs-3">Paul
                                Miles</a>
                                <div className="text-muted fs-6 fw-semibold mt-1">Development Lead</div>
                            </div>
                        </div>
                        <div className="text-center">
                            <div
                                className="avatar-bg octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"></div>
                            <div className="mb-0"><a href="#" className="text-dark fw-bold text-hover-primary fs-3">Melisa
                                Marcus</a>
                                <div className="text-muted fs-6 fw-semibold mt-1">Creative Director</div>
                            </div>
                        </div>
                        <div className="text-center">
                            <div
                                className="avatar-bg octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"></div>
                            <div className="mb-0"><a href="#" className="text-dark fw-bold text-hover-primary fs-3">David
                                Nilson</a>
                                <div className="text-muted fs-6 fw-semibold mt-1">Python Expert</div>
                            </div>
                        </div>
                        <div className="text-center">
                            <div
                                className="avatar-bg octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"></div>
                            <div className="mb-0"><a href="#" className="text-dark fw-bold text-hover-primary fs-3">Anne
                                Clarc</a>
                                <div className="text-muted fs-6 fw-semibold mt-1">Project Manager</div>
                            </div>
                        </div>
                        <div className="text-center">
                            <div
                                className="avatar-bg octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"></div>
                            <div className="mb-0"><a href="#" className="text-dark fw-bold text-hover-primary fs-3">Ricky
                                Hunt</a>
                                <div className="text-muted fs-6 fw-semibold mt-1">Art Director</div>
                            </div>
                        </div>
                        <div className="text-center">
                            <div
                                className="avatar-bg octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"></div>
                            <div className="mb-0"><a href="#" className="text-dark fw-bold text-hover-primary fs-3">Alice
                                Wayde</a>
                                <div className="text-muted fs-6 fw-semibold mt-1">Marketing Manager</div>
                            </div>
                        </div>
                        <div className="text-center">
                            <div
                                className="avatar-bg octagon mx-auto mb-5 d-flex w-200px h-200px bgi-no-repeat bgi-size-contain bgi-position-center"></div>
                            <div className="mb-0"><a href="#" className="text-dark fw-bold text-hover-primary fs-3">Carles
                                Puyol</a>
                                <div className="text-muted fs-6 fw-semibold mt-1">QA Managers</div>
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-icon btn-active-color-primary" id="kt_team_slider_prev"><i
                        className="ki-outline ki-left fs-2x"></i></button>
                    <button className="btn btn-icon btn-active-color-primary" id="kt_team_slider_next"><i
                        className="ki-outline ki-right fs-2x"></i></button>
                </div>
            </div>
        </div>
        <div className="mb-lg-n15 position-relative z-index-2">
            <div className="container">
                <div className="card" style={{filter: 'drop-shadow(0px 0px 40px rgba(68, 81, 96, 0.08))'}}>
                    <div className="card-body p-lg-20">
                        <div className="text-center mb-5 mb-lg-10"><h3 className="fs-2hx text-dark mb-5" id="portfolio"
                                                                       data-kt-scroll-offset="{default: 100, lg: 250}">Our
                            Projects</h3></div>
                        <div className="d-flex flex-center mb-5 mb-lg-15">
                            <ul className="nav border-transparent flex-center fs-5 fw-bold">
                                <li className="nav-item"><a
                                    className="nav-link text-gray-500 text-active-primary px-3 px-lg-6 active" href="#"
                                    data-bs-toggle="tab" data-bs-target="#kt_landing_projects_latest">Latest</a></li>
                                <li className="nav-item"><a
                                    className="nav-link text-gray-500 text-active-primary px-3 px-lg-6" href="#"
                                    data-bs-toggle="tab" data-bs-target="#kt_landing_projects_web_design">Web Design</a>
                                </li>
                                <li className="nav-item"><a
                                    className="nav-link text-gray-500 text-active-primary px-3 px-lg-6" href="#"
                                    data-bs-toggle="tab" data-bs-target="#kt_landing_projects_mobile_apps">Mobile
                                    Apps</a></li>
                                <li className="nav-item"><a
                                    className="nav-link text-gray-500 text-active-primary px-3 px-lg-6" href="#"
                                    data-bs-toggle="tab"
                                    data-bs-target="#kt_landing_projects_development">Development</a></li>
                            </ul>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="kt_landing_projects_latest">
                                <div className="row g-10">
                                    <div className="col-lg-6"><a className="d-block card-rounded overlay h-lg-100"
                                                                 data-fslightbox="lightbox-projects"
                                                                 href="/assets/media/stock/600x600/img-23.jpg">
                                        <div
                                            className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px"></div>
                                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                            className="ki-outline ki-eye fs-3x text-white"></i></div>
                                    </a></div>
                                    <div className="col-lg-6">
                                        <div className="row g-10 mb-10">
                                            <div className="col-lg-6"><a className="d-block card-rounded overlay"
                                                                         data-fslightbox="lightbox-projects"
                                                                         href="/assets/media/stock/600x600/img-22.jpg">
                                                <div
                                                    className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"></div>
                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                                    className="ki-outline ki-eye fs-3x text-white"></i></div>
                                            </a></div>
                                            <div className="col-lg-6"><a className="d-block card-rounded overlay"
                                                                         data-fslightbox="lightbox-projects"
                                                                         href="/assets/media/stock/600x600/img-21.jpg">
                                                <div
                                                    className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"></div>
                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                                    className="ki-outline ki-eye fs-3x text-white"></i></div>
                                            </a></div>
                                        </div>
                                        <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects"
                                           href="/assets/media/stock/600x400/img-20.jpg">
                                            <div
                                                className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"></div>
                                            <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                                className="ki-outline ki-eye fs-3x text-white"></i></div>
                                        </a></div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="kt_landing_projects_web_design">
                                <div className="row g-10">
                                    <div className="col-lg-6"><a className="d-block card-rounded overlay h-lg-100"
                                                                 data-fslightbox="lightbox-projects"
                                                                 href="/assets/media/stock/600x600/img-11.jpg">
                                        <div
                                            className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px"></div>
                                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                            className="ki-outline ki-eye fs-3x text-white"></i></div>
                                    </a></div>
                                    <div className="col-lg-6">
                                        <div className="row g-10 mb-10">
                                            <div className="col-lg-6"><a className="d-block card-rounded overlay"
                                                                         data-fslightbox="lightbox-projects"
                                                                         href="/assets/media/stock/600x600/img-12.jpg">
                                                <div
                                                    className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"></div>
                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                                    className="ki-outline ki-eye fs-3x text-white"></i></div>
                                            </a></div>
                                            <div className="col-lg-6"><a className="d-block card-rounded overlay"
                                                                         data-fslightbox="lightbox-projects"
                                                                         href="/assets/media/stock/600x600/img-21.jpg">
                                                <div
                                                    className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"></div>
                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                                    className="ki-outline ki-eye fs-3x text-white"></i></div>
                                            </a></div>
                                        </div>
                                        <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects"
                                           href="/assets/media/stock/600x400/img-20.jpg">
                                            <div
                                                className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"></div>
                                            <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                                className="ki-outline ki-eye fs-3x text-white"></i></div>
                                        </a></div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="kt_landing_projects_mobile_apps">
                                <div className="row g-10">
                                    <div className="col-lg-6">
                                        <div className="row g-10 mb-10">
                                            <div className="col-lg-6"><a className="d-block card-rounded overlay"
                                                                         data-fslightbox="lightbox-projects"
                                                                         href="/assets/media/stock/600x600/img-16.jpg">
                                                <div
                                                    className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"></div>
                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                                    className="ki-outline ki-eye fs-3x text-white"></i></div>
                                            </a></div>
                                            <div className="col-lg-6"><a className="d-block card-rounded overlay"
                                                                         data-fslightbox="lightbox-projects"
                                                                         href="/assets/media/stock/600x600/img-12.jpg">
                                                <div
                                                    className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"></div>
                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                                    className="ki-outline ki-eye fs-3x text-white"></i></div>
                                            </a></div>
                                        </div>
                                        <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects"
                                           href="/assets/media/stock/600x400/img-15.jpg">
                                            <div
                                                className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"></div>
                                            <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                                className="ki-outline ki-eye fs-3x text-white"></i></div>
                                        </a></div>
                                    <div className="col-lg-6"><a className="d-block card-rounded overlay h-lg-100"
                                                                 data-fslightbox="lightbox-projects"
                                                                 href="/assets/media/stock/600x600/img-23.jpg">
                                        <div
                                            className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px"></div>
                                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                            className="ki-outline ki-eye fs-3x text-white"></i></div>
                                    </a></div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="kt_landing_projects_development">
                                <div className="row g-10">
                                    <div className="col-lg-6"><a className="d-block card-rounded overlay h-lg-100"
                                                                 data-fslightbox="lightbox-projects"
                                                                 href="/assets/media/stock/600x600/img-15.jpg">
                                        <div
                                            className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-lg-100 min-h-250px"></div>
                                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                            className="ki-outline ki-eye fs-3x text-white"></i></div>
                                    </a></div>
                                    <div className="col-lg-6">
                                        <div className="row g-10 mb-10">
                                            <div className="col-lg-6"><a className="d-block card-rounded overlay"
                                                                         data-fslightbox="lightbox-projects"
                                                                         href="/assets/media/stock/600x600/img-22.jpg">
                                                <div
                                                    className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"></div>
                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                                    className="ki-outline ki-eye fs-3x text-white"></i></div>
                                            </a></div>
                                            <div className="col-lg-6"><a className="d-block card-rounded overlay"
                                                                         data-fslightbox="lightbox-projects"
                                                                         href="/assets/media/stock/600x600/img-21.jpg">
                                                <div
                                                    className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"></div>
                                                <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                                    className="ki-outline ki-eye fs-3x text-white"></i></div>
                                            </a></div>
                                        </div>
                                        <a className="d-block card-rounded overlay" data-fslightbox="lightbox-projects"
                                           href="/assets/media/stock/600x400/img-14.jpg">
                                            <div
                                                className="stock-bg overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-250px"></div>
                                            <div className="overlay-layer card-rounded bg-dark bg-opacity-25"><i
                                                className="ki-outline ki-eye fs-3x text-white"></i></div>
                                        </a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="mt-sm-n20">
            <div className="landing-curve landing-dark-color">
                <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                        fill="currentColor"></path>
                </svg>
            </div>
            <div className="py-20 landing-dark-bg">
                <div className="container">
                    <div className="d-flex flex-column container pt-lg-20">
                        <div className="mb-13 text-center"><h1 className="fs-2hx fw-bold text-white mb-5" id="pricing"
                                                               data-kt-scroll-offset="{default: 100, lg: 150}">Clear
                            Pricing Makes it Easy</h1>
                            <div className="text-gray-600 fw-semibold fs-5">Save thousands to millions of bucks by using
                                single tool for different<br/>amazing and outstanding cool and great useful admin
                            </div>
                        </div>
                        <div className="text-center" id="kt_pricing">
                            <div className="nav-group landing-dark-bg d-inline-flex mb-15" data-kt-buttons="true"
                                 style={{border: '1px dashed #2B4666'}}><a href="#"
                                                                           className="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 me-2 active"
                                                                           data-kt-plan="month">Monthly</a><a href="#"
                                                                                                              className="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3"
                                                                                                              data-kt-plan="annual">Annual</a>
                            </div>
                            <div className="row g-10">
                                <div className="col-xl-4">
                                    <div className="d-flex h-100 align-items-center">
                                        <div
                                            className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                                            <div className="mb-7 text-center"><h1
                                                className="text-dark mb-5 fw-boldest">Startup</h1>
                                                <div className="text-gray-400 fw-semibold mb-5">Best Settings for
                                                    Startups
                                                </div>
                                                <div className="text-center"><span
                                                    className="mb-2 text-primary">$</span><span
                                                    className="fs-3x fw-bold text-primary" data-kt-plan-price-month="99"
                                                    data-kt-plan-price-annual="999">99</span><span
                                                    className="fs-7 fw-semibold opacity-50"
                                                    data-kt-plan-price-month="/ Mon" data-kt-plan-price-annual="/ Ann">/ Mon</span>
                                                </div>
                                            </div>
                                            <div className="w-100 mb-10">
                                                <div className="d-flex flex-stack mb-5"><span
                                                    className="fw-semibold fs-6 text-gray-800 text-start pe-3">Up to 10 Active Users</span><i
                                                    className="ki-outline ki-check-circle fs-1 text-success"></i></div>
                                                <div className="d-flex flex-stack mb-5"><span
                                                    className="fw-semibold fs-6 text-gray-800 text-start pe-3">Up to 30 Project Integrations</span><i
                                                    className="ki-outline ki-check-circle fs-1 text-success"></i></div>
                                                <div className="d-flex flex-stack mb-5"><span
                                                    className="fw-semibold fs-6 text-gray-800">Keen Analytics Platform</span><i
                                                    className="ki-outline ki-cross-circle fs-1"></i></div>
                                                <div className="d-flex flex-stack mb-5"><span
                                                    className="fw-semibold fs-6 text-gray-800">Targets Timelines & Files</span><i
                                                    className="ki-outline ki-cross-circle fs-1"></i></div>
                                                <div className="d-flex flex-stack"><span
                                                    className="fw-semibold fs-6 text-gray-800">Unlimited Projects</span><i
                                                    className="ki-outline ki-cross-circle fs-1"></i></div>
                                            </div>
                                            <a href="#" className="btn btn-primary">Select</a></div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="d-flex h-100 align-items-center">
                                        <div
                                            className="w-100 d-flex flex-column flex-center rounded-3 bg-primary py-20 px-10">
                                            <div className="mb-7 text-center"><h1
                                                className="text-white mb-5 fw-boldest">Business</h1>
                                                <div className="text-white opacity-75 fw-semibold mb-5">Best Settings
                                                    for Business
                                                </div>
                                                <div className="text-center"><span
                                                    className="mb-2 text-white">$</span><span
                                                    className="fs-3x fw-bold text-white" data-kt-plan-price-month="199"
                                                    data-kt-plan-price-annual="1999">199</span><span
                                                    className="fs-7 fw-semibold text-white opacity-75"
                                                    data-kt-plan-price-month="/ Mon" data-kt-plan-price-annual="/ Ann">/ Mon</span>
                                                </div>
                                            </div>
                                            <div className="w-100 mb-10">
                                                <div className="d-flex flex-stack mb-5"><span
                                                    className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Up to 10 Active Users</span><i
                                                    className="ki-outline ki-check-circle fs-1 text-white"></i></div>
                                                <div className="d-flex flex-stack mb-5"><span
                                                    className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Up to 30 Project Integrations</span><i
                                                    className="ki-outline ki-check-circle fs-1 text-white"></i></div>
                                                <div className="d-flex flex-stack mb-5"><span
                                                    className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Keen Analytics Platform</span><i
                                                    className="ki-outline ki-check-circle fs-1 text-white"></i></div>
                                                <div className="d-flex flex-stack mb-5"><span
                                                    className="fw-semibold fs-6 text-white opacity-75 text-start pe-3">Targets Timelines & Files</span><i
                                                    className="ki-outline ki-check-circle fs-1 text-white"></i></div>
                                                <div className="d-flex flex-stack"><span
                                                    className="fw-semibold fs-6 text-white opacity-75">Unlimited Projects</span><i
                                                    className="ki-outline ki-cross-circle fs-1 text-white"></i></div>
                                            </div>
                                            <a href="#"
                                               className="btn btn-color-primary btn-active-light-primary btn-light">Select</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="d-flex h-100 align-items-center">
                                        <div
                                            className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                                            <div className="mb-7 text-center"><h1
                                                className="text-dark mb-5 fw-boldest">Enterprise</h1>
                                                <div className="text-gray-400 fw-semibold mb-5">Best Settings for
                                                    Enterprise
                                                </div>
                                                <div className="text-center"><span
                                                    className="mb-2 text-primary">$</span><span
                                                    className="fs-3x fw-bold text-primary"
                                                    data-kt-plan-price-month="999"
                                                    data-kt-plan-price-annual="9999">999</span><span
                                                    className="fs-7 fw-semibold opacity-50"
                                                    data-kt-plan-price-month="/ Mon" data-kt-plan-price-annual="/ Ann">/ Mon</span>
                                                </div>
                                            </div>
                                            <div className="w-100 mb-10">
                                                <div className="d-flex flex-stack mb-5"><span
                                                    className="fw-semibold fs-6 text-gray-800 text-start pe-3">Up to 10 Active Users</span><i
                                                    className="ki-outline ki-check-circle fs-1 text-success"></i></div>
                                                <div className="d-flex flex-stack mb-5"><span
                                                    className="fw-semibold fs-6 text-gray-800 text-start pe-3">Up to 30 Project Integrations</span><i
                                                    className="ki-outline ki-check-circle fs-1 text-success"></i></div>
                                                <div className="d-flex flex-stack mb-5"><span
                                                    className="fw-semibold fs-6 text-gray-800 text-start pe-3">Keen Analytics Platform</span><i
                                                    className="ki-outline ki-check-circle fs-1 text-success"></i></div>
                                                <div className="d-flex flex-stack mb-5"><span
                                                    className="fw-semibold fs-6 text-gray-800 text-start pe-3">Targets Timelines & Files</span><i
                                                    className="ki-outline ki-check-circle fs-1 text-success"></i></div>
                                                <div className="d-flex flex-stack"><span
                                                    className="fw-semibold fs-6 text-gray-800 text-start pe-3">Unlimited Projects</span><i
                                                    className="ki-outline ki-check-circle fs-1 text-success"></i></div>
                                            </div>
                                            <a href="#" className="btn btn-primary">Select</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="landing-curve landing-dark-color">
                <svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z"
                        fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <div className="mt-20 mb-n20 position-relative z-index-2">
            <div className="container">
                <div className="text-center mb-17"><h3 className="fs-2hx text-dark mb-5" id="clients"
                                                       data-kt-scroll-offset="{default: 125, lg: 150}">What Our Clients
                    Say</h3>
                    <div className="fs-5 text-muted fw-bold">Save thousands to millions of bucks by using single
                        tool<br/>for different amazing and great useful admin
                    </div>
                </div>
                <div className="row g-lg-10 mb-10 mb-lg-20">
                    <div className="col-lg-4">
                        <div
                            className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                            <div className="mb-7">
                                <div className="rating mb-6">
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                </div>
                                <div className="fs-2 fw-bold text-dark mb-3">This is by far the cleanest template<br/>and
                                    the most well structured
                                </div>
                                <div className="text-gray-500 fw-semibold fs-4">The most well thought out design theme I
                                    have ever used. The codes are up to tandard. The css styles are very clean. In fact
                                    the cleanest and the most up to standard I have ever seen.
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-circle symbol-50px me-5"><img
                                    src="/assets/media/avatars/300-1.jpg" className="" alt=""/></div>
                                <div className="flex-grow-1"><a href="#"
                                                                className="text-dark fw-bold text-hover-primary fs-6">Paul
                                    Miles</a><span className="text-muted d-block fw-bold">Development Lead</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div
                            className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                            <div className="mb-7">
                                <div className="rating mb-6">
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                </div>
                                <div className="fs-2 fw-bold text-dark mb-3">This is by far the cleanest template<br/>and
                                    the most well structured
                                </div>
                                <div className="text-gray-500 fw-semibold fs-4">The most well thought out design theme I
                                    have ever used. The codes are up to tandard. The css styles are very clean. In fact
                                    the cleanest and the most up to standard I have ever seen.
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-circle symbol-50px me-5"><img
                                    src="/assets/media/avatars/300-2.jpg" className="" alt=""/></div>
                                <div className="flex-grow-1"><a href="#"
                                                                className="text-dark fw-bold text-hover-primary fs-6">Janya
                                    Clebert</a><span className="text-muted d-block fw-bold">Development Lead</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div
                            className="d-flex flex-column justify-content-between h-lg-100 px-10 px-lg-0 pe-lg-10 mb-15 mb-lg-0">
                            <div className="mb-7">
                                <div className="rating mb-6">
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                    <div className="rating-label me-2 checked"><i
                                        className="ki-outline ki-star fs-5"></i></div>
                                </div>
                                <div className="fs-2 fw-bold text-dark mb-3">This is by far the cleanest template<br/>and
                                    the most well structured
                                </div>
                                <div className="text-gray-500 fw-semibold fs-4">The most well thought out design theme I
                                    have ever used. The codes are up to tandard. The css styles are very clean. In fact
                                    the cleanest and the most up to standard I have ever seen.
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-circle symbol-50px me-5"><img
                                    src="/assets/media/avatars/300-16.jpg" className="" alt=""/></div>
                                <div className="flex-grow-1"><a href="#"
                                                                className="text-dark fw-bold text-hover-primary fs-6">Steave
                                    Brown</a><span className="text-muted d-block fw-bold">Development Lead</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 p-lg-12 mb-n5 mb-lg-n13"
                    style={{background: 'linear-gradient(90deg, #20AA3E 0%, #03A588 100%)'}}>
                    <div className="my-2 me-5">
                        <div className="fs-1 fs-lg-2qx fw-bold text-white mb-2">Start With Metronic Today,<span
                            className="fw-normal">Speed Up Development!</span></div>
                        <div className="fs-6 fs-lg-5 text-white fw-semibold opacity-75">Join over 100,000 Professionals
                            Community to Stay Ahead
                        </div>
                    </div>
                    <a href="https://1.envato.market/EA4JP"
                       className="btn btn-lg btn-outline border-2 btn-outline-white flex-shrink-0 my-2">Purchase on
                        Themeforest</a></div>
            </div>
        </div>
        <div className="mb-0">
            <div className="landing-curve landing-dark-color">
                <svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z"
                        fill="currentColor"></path>
                </svg>
            </div>
            <div className="landing-dark-bg pt-20">
                <div className="container">
                    <div className="row py-10 py-lg-20">
                        <div className="col-lg-6 pe-lg-16 mb-10 mb-lg-0">
                            <div className="rounded landing-dark-border p-9 mb-10"><h2 className="text-white">Would you
                                need a Custom License?</h2><span className="fw-normal fs-4 text-gray-700">Email us to<a
                                href="https://keenthemes.com/support"
                                className="text-white opacity-50 text-hover-primary">support@keenthemes.com</a></span>
                            </div>
                            <div className="rounded landing-dark-border p-9"><h2 className="text-white">How About a
                                Custom Project?</h2><span className="fw-normal fs-4 text-gray-700">Use Our Custom Development Service.<a
                                href="../../demo34/dist/pages/user-profile/overview.html"
                                className="text-white opacity-50 text-hover-primary">Click to Get a Quote</a></span>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-16">
                            <div className="d-flex justify-content-center">
                                <div className="d-flex fw-semibold flex-column me-20"><h4
                                    className="fw-bold text-gray-400 mb-6">More for Metronic</h4><a
                                    href="https://keenthemes.com/faqs"
                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">FAQ</a><a
                                    href="https://preview.keenthemes.com/html/metronic/docs"
                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Documentaions</a><a
                                    href="https://www.youtube.com/c/KeenThemesTuts/videos"
                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Video Tuts</a><a
                                    href="https://preview.keenthemes.com/html/metronic/docs/getting-started/changelog"
                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Changelog</a><a
                                    href="https://devs.keenthemes.com/"
                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Support Forum</a><a
                                    href="https://keenthemes.com/blog"
                                    className="text-white opacity-50 text-hover-primary fs-5">Blog</a></div>
                                <div className="d-flex fw-semibold flex-column ms-lg-20"><h4
                                    className="fw-bold text-gray-400 mb-6">Stay Connected</h4><a
                                    href="https://www.facebook.com/keenthemes" className="mb-6"><img
                                    src="/assets/media/svg/brand-logos/facebook-4.svg" className="h-20px me-2"
                                    alt=""/><span
                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Facebook</span></a><a
                                    href="https://github.com/KeenthemesHub" className="mb-6"><img
                                    src="/assets/media/svg/brand-logos/github.svg" className="h-20px me-2" alt=""/><span
                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Github</span></a><a
                                    href="https://twitter.com/keenthemes" className="mb-6"><img
                                    src="/assets/media/svg/brand-logos/twitter.svg" className="h-20px me-2"
                                    alt=""/><span
                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Twitter</span></a><a
                                    href="https://dribbble.com/keenthemes" className="mb-6"><img
                                    src="/assets/media/svg/brand-logos/dribbble-icon-1.svg" className="h-20px me-2"
                                    alt=""/><span
                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Dribbble</span></a><a
                                    href="https://www.instagram.com/keenthemes" className="mb-6"><img
                                    src="/assets/media/svg/brand-logos/instagram-2-1.svg" className="h-20px me-2"
                                    alt=""/><span
                                    className="text-white opacity-50 text-hover-primary fs-5 mb-6">Instagram</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="landing-dark-separator"></div>
                <div className="container">
                    <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                        <div className="d-flex align-items-center order-2 order-md-1"><a
                            href="../../demo34/dist/landing.html"><img alt="Logo" src="/assets/media/logos/landing.svg"
                                                                       className="h-15px h-md-20px"/></a><span
                            className="mx-5 fs-6 fw-semibold text-gray-600 pt-1">&copy; 2023 Keenthemes Inc.</span>
                        </div>
                        <ul className="menu menu-gray-600 menu-hover-primary fw-semibold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                            <li className="menu-item"><a href="https://keenthemes.com" target="_blank"
                                                         className="menu-link px-2">About</a></li>
                            <li className="menu-item mx-5"><a href="https://devs.keenthemes.com" target="_blank"
                                                              className="menu-link px-2">Support</a></li>
                            <li className="menu-item"><a href="" target="_blank" className="menu-link px-2">Purchase</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true"><i className="ki-outline ki-arrow-up"></i>
        </div>
    </React.Fragment>);
}

export default App;
